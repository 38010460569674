import Vue from 'vue'
import VueRouter from 'vue-router'

const APP_NAME = 'Mes Services Assurance'

Vue.use(VueRouter)

const COLLABORATOR = 'collaborateur'
const ADMINISTRATOR = 'administrateur'
const GUEST = 'guest'
const MANAGER = 'manager';
const ACCOUNTANT = 'accountant';
const SOCIAL_OFFICE = 'social_office';


const routes = [{
    path: '/',
    redirect: 'auth/login',
    meta: {
      title: APP_NAME,
    }
  },
  {
    path: '/services/charges',
    name: 'serviceSuppliers',
    component: () => import('@/views/pages/services/charges/MySuppliers.vue'),
    meta: {
      title: `${APP_NAME} - Mes fournisseurs`,
      rights: [COLLABORATOR, ADMINISTRATOR],

    },

  },
  {
    path: '/services/commissions',
    name: 'serviceCommissions',
    component: () => import('@/views/pages/services/commissions/Commissions.vue'),
    meta: {
      title: `${APP_NAME} - Mes commissions`,
      rights: [ADMINISTRATOR],
    }

  },

  {
    path: '/services/products',
    name: 'serviceProducts',
    component: () => import('@/views/pages/services/products/Products.vue'),
    meta: {
      title: `${APP_NAME} - Mes produits`,
      rights: [COLLABORATOR, ADMINISTRATOR],
    }

  },

  {
    path: '/services/products/items',
    name: 'serviceProductsItems',
    component: () => import('@/views/pages/services/products/ProductsItems.vue'),
    meta: {
      rights: [COLLABORATOR, ADMINISTRATOR],
    }

  },

  {
    path: '/services/partners',
    name: 'servicePartners',
    component: () => import('@/views/pages/services/partners/Partners.vue'),
    meta: {
      title: `${APP_NAME} - Mes partenaires`,
      rights: [COLLABORATOR, ADMINISTRATOR],
    }

  },

  {
    path: '/services/partners/items',
    name: 'servicePartnersItems',
    component: () => import('@/views/pages/services/partners/PartnersItems.vue'),
    meta: {
      rights: [COLLABORATOR, ADMINISTRATOR],
    }

  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/pages/services/contact/Contact.vue'),
    meta: {
      title: `${APP_NAME} - Demandes de rappel`,
      rights: [COLLABORATOR, ADMINISTRATOR],

    }

  },
  {
    path: '/colleague',
    name: 'colleague',
    component: () => import('@/views/pages/services/colleague/Colleague.vue'),
    meta: {
      title: `${APP_NAME} - Mes collègues`,
      rights: [COLLABORATOR, ADMINISTRATOR],

    }

  },
  {
    path: '/services/lddc',
    name: 'serviceLddc',
    component: () => import('@/views/pages/services/generator/Lddc.vue'),
    meta: {
      title: `${APP_NAME} - LDDC`,
      rights: [COLLABORATOR, ADMINISTRATOR],

    }

  },
  {
    path: '/superadmin/validator',
    name: 'validator',
    component: () => import('@/views/pages/admin/WaitingPartners.vue'),
    meta: {
      title: 'Administration - Validation des partenaires',
      rights: [ADMINISTRATOR],

    }
  },
  {
    path: '/services/mandatetude',
    name: 'serviceMandatEtude',
    component: () => import('@/views/pages/services/generator/MandatEtude.vue'),
    meta: {
      title: `${APP_NAME} - Mandat d'étude`,
      rights: [COLLABORATOR, ADMINISTRATOR],

    }

  },
  {
    path: '/services/parrain',
    name: 'serviceParrain',
    component: () => import('@/views/pages/services/Parrainage.vue'),
    meta: {
      title: `${APP_NAME} - Parrainage`,
      rights: [COLLABORATOR, ADMINISTRATOR, GUEST],
    }

  },
  {
    path: '/services/appointment',
    name: 'serviceAppointment',
    component: () => import('@/views/pages/services/contact/Appointment.vue'),
    meta: {
      title: `${APP_NAME} - Prise de rendez-vous`,
      rights: [COLLABORATOR, ADMINISTRATOR],
    }

  },
  {
    path: '/services/sponsor',
    name: 'serviceAdminSponsor',
    component: () => import('@/views/pages/services/sponsoring/Sponsoring.vue'),
    meta: {
      title: `${APP_NAME} - Parrainage`,
      rights: [COLLABORATOR, ADMINISTRATOR],
    }

  },
  {
    path: '/services/extranet',
    name: 'serviceExtranet',
    component: () => import('@/views/pages/services/extranets/Extranet.vue'),
    meta: {
      title: `${APP_NAME} - Extranet`,
      rights: [COLLABORATOR, ADMINISTRATOR],

    }

  },
  {
    path: '/services/forms',
    name: 'serviceForms',
    component: () => import('@/views/pages/services/forms/Forms.vue'),
    meta: {
      title: `${APP_NAME} - Mes formulaires`,
      rights: [COLLABORATOR, ADMINISTRATOR],

    }

  },
  {
    path: '/client',
    name: 'client',
    component: () => import('@/views/pages/customers/Customers.vue'),
    meta: {
      title: `${APP_NAME} - Mes clients`,
      rights: [COLLABORATOR, ADMINISTRATOR],

    }

  },
  {
    path: '/settings/office',
    name: 'settingsOffice',
    component: () => import('@/views/pages/office-settings/OfficeDashboard.vue'),
    meta: {
      title: `${APP_NAME} - Paramètres de mon agence`,
      rights: [ADMINISTRATOR],

    }
  },
  {
    path: '/office',
    name: 'Office',
    component: () => import('@/views/pages/office-settings/office/Office.vue'),
    meta: {
      title: `${APP_NAME} - Paramètres de mon agence`,
      rights: [ADMINISTRATOR],

    }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/pages/office-settings/products/Products.vue'),
    meta: {
      title: `${APP_NAME} - Produits de mon agence`,
      rights: [ADMINISTRATOR],

    }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('@/views/pages/office-settings/partners/Partners.vue'),
    meta: {
      title: `${APP_NAME} - Partenaires de mon agence`,
      rights: [ADMINISTRATOR],

    }
  },
  {
    path: '/commissions',
    name: 'Commissions',
    component: () => import('@/views/pages/office-settings/commissions/Commissions.vue'),
    meta: {
      title: `${APP_NAME} - Commissions de mon agence`,
      rights: [ADMINISTRATOR],

    }
  },
  // {
  //   path: '/vigilance',
  //   name: 'vigilance',
  //   component: () => import('@/views/pages/services/vigilance/Vigilance.vue'),
  //   meta: {
  //     title: 'Vigilance client',
  //     rights: [ADMINISTRATOR],

  //   }
  // },
  {
    path: '/client/configuration',
    name: 'configurationClient',
    component: () => import('@/views/pages/client/ClientSettings.vue'),
    meta: {
      title: 'Mon configurateur',
      rights: [COLLABORATOR, ADMINISTRATOR],

    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/dashboard/Dashboard.vue'),
    meta: {
      title: `${APP_NAME} - Tableau de bord`,
      rights: [COLLABORATOR, ADMINISTRATOR, GUEST],
    }
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      title: `${APP_NAME} - Paramètres du compte`,
      rights: [COLLABORATOR, ADMINISTRATOR],
    }
  },
  {
    path: '/legal/mentions',
    name: 'legalMentions',
    component: () => import('@/views/pages/legal/LegalNotice.vue'),
    meta: {
      title: `${APP_NAME} - Mentions légales`,
      rights: [COLLABORATOR, ADMINISTRATOR, GUEST],
    }
  },
  {
    path: '/legal/privacy',
    name: 'legalPrivacy',
    component: () => import('@/views/pages/legal/PrivacyPolicy.vue'),
    meta: {
      title: `${APP_NAME} - Politique de confidentialité`,
      rights: [COLLABORATOR, ADMINISTRATOR, GUEST],
    }
  },
  {
    path: '/services/table',
    name: 'serviceTable',
    component: () => import('@/views/pages/services/table/Table.vue'),
    meta: {
      title: `${APP_NAME} - Tableau de bord de mon agence`,
      rights: [COLLABORATOR, ADMINISTRATOR],
    }
  },

  {
    path: '/services/complaints',
    name: 'complaints',
    component: () => import('@/views/pages/services/complaints/Complaints.vue'),
    meta: {
      title: `${APP_NAME} - Réclamations`,
      rights: [COLLABORATOR, ADMINISTRATOR],

    },
  },
  {
    path: '/services/complaints/items',
    name: 'serviceComplaintsItems',
    component: () => import('@/views/pages/services/complaints/ComplaintsItems.vue'),
    meta: {
      rights: [COLLABORATOR, ADMINISTRATOR],
    }
  },
  {
    path: '/auth/login',
    name: 'pages-login',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      title: `${APP_NAME} - Connectez-vous !`,
      layout: 'blank',
      dataUser: {
        name: "",
      }
    },
  },

  {
    path: '/auth/register/office',
    name: 'pages-register-office',
    component: () => import('@/views/pages/auth/RegisterOffice.vue'),
    meta: {
      title: `${APP_NAME} - Inscrivez-vous !`,
      layout: 'blank',
    },
  },
  {
    path: '/auth/register/client',
    name: 'pages-register-client',
    component: () => import('@/views/pages/auth/RegisterClient.vue'),
    meta: {
      title: `${APP_NAME} - Inscrivez-vous !`,
      layout: 'blank',
    },
  },



  {
    path: '/auth/complete',
    name: 'complete',
    component: () => import('@/views/pages/auth/RegisterComplete.vue'),
    meta: {
      title: `${APP_NAME} - Merci pour votre inscription !`,
      layout: 'blank',
    },
  },
  {
    path: '/off',
    name: 'offline',
    component: () => import('@/views/pages/auth/InternetOffline.vue'),
    meta: {
      title: `${APP_NAME} - Une erreur est survenue !`,
      layout: 'blank',
    },
  },
  {
    path: '/session-expired',
    name: 'session-expired',
    component: () => import('@/views/pages/auth/SessionExpired.vue'),
    meta: {
      title: `${APP_NAME} - Votre session a expiré !`,
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/auth/Error.vue'),
    meta: {
      title: `${APP_NAME} - Une erreur est survenue !`,
      rights: [COLLABORATOR, ADMINISTRATOR, GUEST],

    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router

//! Function to verify if user is authenticate correctly
router.beforeEach((to, from, next) => {
  // Define pages that don't require authentication
  const publicPages = ['/auth/login', '/auth/register/office', '/auth/complete',
    '/auth/register/client', '/session-expired'
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('complete_name') || localStorage.getItem("grade");
  const onLoginPageOrRegister = to.path.startsWith('/auth/login') || to.path.startsWith(
    '/auth/register');

  // Check if user is authenticated via cookies
  const isAuthenticated = document.cookie.match(/^(.*;)?\s*expiration\s*=\s*[^;]+(.*)?$/);

  // Redirect to dashboard if logged in and trying to access login or register
  if (loggedIn && isAuthenticated && onLoginPageOrRegister) {
    next('/dashboard');
    return;
  }

  // Existing authentication and redirection logic...
  if (authRequired && !isAuthenticated) {
    // Your existing session expiration logic
    next('/session-expired');
  } else if (authRequired && !loggedIn) {
    // Redirect to login page if not logged in
    next('/auth/login');
  } else {
    // Access control logic (existing code)
    const REQUIRED_RIGHTS = to.meta.rights;
    if (REQUIRED_RIGHTS && REQUIRED_RIGHTS.length > 0 && !REQUIRED_RIGHTS.includes(localStorage
        .getItem("grade"))) {
      next('/error-404');
    } else {
      next();
    }
  }
});



//? Function to name a tab with correct value
const DEFAULT_TITLE = 'Mes Services Assurance';
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});
