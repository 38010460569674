var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"app-padding"},[_c('vue-topprogress',{ref:"topProgress",attrs:{"color":"#FCC03C"}}),_c('v-main',{staticClass:"main-padding"},[_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.dialogAlert),callback:function ($$v) {_vm.dialogAlert=$$v},expression:"dialogAlert"}},[_c('v-card',[_c('v-card-title',[_vm._v("Ajouter une nouvelle alerte")]),_c('v-card-text',[_vm._v(" Alertes en cours : ")]),_c('v-card-text',[_c('v-list',_vm._l((_vm.alerts),function(alert){return _c('v-list-item',{key:alert.id,attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-alert',{attrs:{"text":"","prominent":"","dense":"","border":"top","type":alert.state}},[_c('strong',[_vm._v(_vm._s(alert.datetime_display))]),_vm._v(" "+_vm._s(alert.message)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"EoleError","icon":""},on:{"click":function($event){return _vm.deleteAlert(alert.id)}}},[_c('i',{staticClass:"fa-solid fa-trash EoleError--text"})])],1)],1)}),1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Nouveau message d'alerte"},model:{value:(_vm.newAlert.message),callback:function ($$v) {_vm.$set(_vm.newAlert, "message", $$v)},expression:"newAlert.message"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":[
                  {
                    text: 'Erreur',
                    value: 'error',
                  },
                  {
                    text: 'Succès',
                    value: 'success',
                  },
                  {
                    text: 'Information',
                    value: 'info',
                  },
                  {
                    text: 'Avertissement',
                    value: 'warning',
                  } ],"label":"Type d'alerte","dense":"","outlined":""},model:{value:(_vm.newAlert.state),callback:function ($$v) {_vm.$set(_vm.newAlert, "state", $$v)},expression:"newAlert.state"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogAlert = false}}},[_vm._v("Annuler")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.postAlert}},[_vm._v("Ajouter")])],1)],1)],1),_c('div',{staticClass:"app-content-container pa-5"},[(_vm.alerts.length > 0)?_c('v-alert',{attrs:{"text":"","prominent":"","dense":"","border":"top","type":_vm.alerts[0].state}},[_c('marquee',{attrs:{"behavior":"","direction":""}},[_c('strong',[_vm._v(_vm._s(_vm.messages))])])],1):_vm._e(),_vm._t("default")],2),_c('vertical-nav-menu',{attrs:{"count":_vm.count,"isDrawerOpen":_vm.isDrawerOpen},on:{"notification-opened":_vm.handleNotificationOpen}}),(_vm.loggedIn)?_c('vertical-nav-notification',{attrs:{"isOpen":_vm.notificationOpened},on:{"count":_vm.handleCount}}):_vm._e(),(_vm.loggedIn && _vm.isAdmin)?_c('v-speed-dial',{ref:"speedDial",attrs:{"fixed":"","bottom":"","right":""},nativeOn:{"mousedown":function($event){$event.preventDefault();return _vm.dragStart.apply(null, arguments)},"touchstart":function($event){$event.preventDefault();return _vm.dragStart.apply(null, arguments)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"EoleBlue","dark":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-tools ")])],1)]},proxy:true}],null,false,340507005)},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"target":"_blank","attr":"","small":"","fab":"","dark":"","color":"EoleBlueLighten"},on:{"click":function($event){_vm.dialogAlert = true}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fa-solid fa-warning EoleError--text"})])]}}],null,false,1738639813)},[_c('span',[_vm._v("Nouvelle alerte")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":"https://status.anavel.bzh/status/anavel","target":"_blank","attr":"","small":"","fab":"","dark":"","color":"EoleBlueLighten"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fa-solid fa-wifi EoleBlue--text"})])]}}],null,false,497234835)},[_c('span',[_vm._v("Voir les services opérationnels")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":"mailto:roman.frichot@anavel.bzh?subject=Signalement d'un bug sur Mes Services&body=Descriptif du bug...","attr":"","small":"","fab":"","dark":"","color":"EoleBlueLighten"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fa-solid fa-bug EoleBlue--text"})])]}}],null,false,919703361)},[_c('span',[_vm._v("Signaler un bug")])])],1):_vm._e()],1),(!_vm.$vuetify.breakpoint.xs)?_c('v-footer',{staticClass:"footer-padding my-3",attrs:{"padless":"","color":"transparent"}},[_c('div',{staticClass:"boxed-container w-full"},[_c('div',{staticClass:"mx-6 d-flex justify-space-between"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","align-self":"center"}},[_vm._v(" "+_vm._s(_vm.appName)+" "+_vm._s(_vm.version)+" © "+_vm._s(new Date().getFullYear())+" "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://anavel.bzh","target":"_blank"}},[_vm._v("Un service proposé par Anavel")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},_vm._l((_vm.links),function(link){return _c('v-btn',{key:link.text,attrs:{"text":"","rounded":"","href":link.href,"target":"_blank"}},[_vm._v(" "+_vm._s(link.text)+" ")])}),1)],1)],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }