<template>
  <v-app class="app-padding">
    <vue-topprogress color="#FCC03C" ref="topProgress"></vue-topprogress>
    <v-main class="main-padding">
      <v-dialog v-model="dialogAlert" width="600px">
        <v-card>
          <v-card-title>Ajouter une nouvelle alerte</v-card-title>
          <v-card-text> Alertes en cours : </v-card-text>
          <v-card-text>
            <v-list>
              <v-list-item v-for="alert in alerts" v-bind:key="alert.id" dense>
                <v-list-item-content>
                  <v-alert text prominent dense border="top" :type="alert.state">
                    <strong>{{ alert.datetime_display }}</strong> {{ alert.message }}
                  </v-alert>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn color="EoleError" icon @click="deleteAlert(alert.id)">
                    <i class="fa-solid fa-trash EoleError--text"></i>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field outlined dense v-model="newAlert.message" label="Nouveau message d'alerte"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="[
                    {
                      text: 'Erreur',
                      value: 'error',
                    },
                    {
                      text: 'Succès',
                      value: 'success',
                    },
                    {
                      text: 'Information',
                      value: 'info',
                    },
                    {
                      text: 'Avertissement',
                      value: 'warning',
                    },
                  ]"
                  v-model="newAlert.state"
                  label="Type d'alerte"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="dialogAlert = false">Annuler</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="postAlert">Ajouter</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="app-content-container pa-5">
        <v-alert v-if="alerts.length > 0" text prominent dense border="top" :type="alerts[0].state">
          <marquee behavior="" direction="">
            <strong>{{ messages }}</strong></marquee
          >
        </v-alert>
        <slot></slot>
      </div>
      <vertical-nav-menu
        :count="count"
        @notification-opened="handleNotificationOpen"
        :isDrawerOpen="isDrawerOpen"
      ></vertical-nav-menu>

      <vertical-nav-notification
        v-if="loggedIn"
        @count="handleCount"
        :isOpen="notificationOpened"
      ></vertical-nav-notification>
      <v-speed-dial
        v-if="loggedIn && isAdmin"
        ref="speedDial"
        fixed
        bottom
        right
        @mousedown.native.prevent="dragStart"
        @touchstart.native.prevent="dragStart"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="EoleBlue" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-tools </v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              target="_blank"
              v-on="on"
              v-bind="attrs"
              attr
              small
              fab
              dark
              color="EoleBlueLighten"
              @click="dialogAlert = true"
            >
              <i class="fa-solid fa-warning EoleError--text"></i>
            </v-btn>
          </template>

          <span>Nouvelle alerte</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              href="https://status.anavel.bzh/status/anavel"
              target="_blank"
              v-on="on"
              v-bind="attrs"
              attr
              small
              fab
              dark
              color="EoleBlueLighten"
            >
              <i class="fa-solid fa-wifi EoleBlue--text"></i
            ></v-btn>
          </template>
          <span>Voir les services opérationnels</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              href="mailto:roman.frichot@anavel.bzh?subject=Signalement d'un bug sur Mes Services&body=Descriptif du bug..."
              v-on="on"
              v-bind="attrs"
              attr
              small
              fab
              dark
              color="EoleBlueLighten"
            >
              <i class="fa-solid fa-bug EoleBlue--text"></i
            ></v-btn>
          </template>
          <span>Signaler un bug</span>
        </v-tooltip>
      </v-speed-dial>
    </v-main>
    <v-footer v-if="!$vuetify.breakpoint.xs" padless color="transparent" class="footer-padding my-3">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <v-row>
            <v-col cols="12" md="6" align-self="center">
              {{ appName }} {{ version }} &copy; {{ new Date().getFullYear() }}
              <a href="https://anavel.bzh" class="text-decoration-none" target="_blank"
                >Un service proposé par Anavel</a
              >
            </v-col>
            <v-col cols="12" md="6" class="text-right">
              <v-btn v-for="link in links" :key="link.text" text rounded :href="link.href" target="_blank">
                {{ link.text }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import vueTopprogress from "vue-top-progress"
import Vue from "vue"
import moment from "moment"
moment.locale("fr")
import config from "@/views/config/config"

Vue.use(vueTopprogress)
import { ref } from "@vue/composition-api"
import { mdiMagnify, mdiBellOutline, mdiGithub } from "@mdi/js"
import VerticalNavMenu from "./components/vertical-nav-menu/VerticalNavMenu.vue"
import VerticalNavNotification from "./components/vertical-nav-menu/VerticalNavNotification.vue"

import ThemeSwitcher from "./components/ThemeSwitcher.vue"
import AppBarUserMenu from "./components/AppBarUserMenu.vue"
import Shortcuts from "./components/Shortcuts.vue"
import { socket } from "@/utils/socket"

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    Shortcuts,
    VerticalNavNotification,
  },
  data: () => ({
    right: 20, // Position de départ - droite
    bottom: 20, // Position de départ - bas
    start: { x: 0, y: 0 },
    newAlert: {
      state: "success",
      message: "",
    },
    dialogAlert: false,
    loggedIn: true,
    hover: false,
    direction: "top",
    fab: false,

    transition: "slide-y-reverse-transition",
    notificationOpened: false,
    openNotification: false,
    drawer: null,
    links: [
      {
        text: "Mentions légales",
        href: "/legal/mentions",
        target: "_blank",
      },
      {
        text: "Charte de confidentialité",
        href: "/legal/privacy",
        target: "_blank",
      },
    ],
    items: [
      { title: "Home", icon: "mdi-view-dashboard" },
      { title: "About", icon: "mdi-forum" },
    ],
    date: null,
    version: config.informations.version,
    appName: config.applicationName,
    show: false,
    loading: false,
    items: [],
    search: null,
    select: null,
    updateTime: null,
    drawerOpen: true,
    count: 0,
    alerts: [],
    messages: "",
    isAdmin: false,
  }),
  created() {
    this.isAdmin = localStorage.getItem("grade") === "administrateur"
    this.updateTime = localStorage.getItem("update_time")
    this.date = moment().format("dddd Do MMMM YYYY")

    if (localStorage.getItem("complete_name")) {
      this.loggedIn = true
    } else {
      this.loggedIn = false
    }

    this.fetchAlerts()
  },
  mounted() {
    this.$refs.topProgress.start()
    this.$nextTick(() => {
      this.$refs.topProgress.done()
    })
    socket.on("newAlert", () => {
      this.fetchAlerts()
    })
  },

  methods: {
    dragStart(event) {
      const speedDial = this.$refs.speedDial.$el

      // Determine starting position
      let startX = event.type.includes("touch") ? event.touches[0].clientX : event.clientX
      let startY = event.type.includes("touch") ? event.touches[0].clientY : event.clientY
      let startRight = window.innerWidth - speedDial.offsetLeft - speedDial.offsetWidth
      let startBottom = window.innerHeight - speedDial.offsetTop - speedDial.offsetHeight

      const move = moveEvent => {
        let clientX = moveEvent.type.includes("touch") ? moveEvent.touches[0].clientX : moveEvent.clientX
        let clientY = moveEvent.type.includes("touch") ? moveEvent.touches[0].clientY : moveEvent.clientY

        let newRight = startRight - (clientX - startX)
        let newBottom = startBottom - (clientY - startY)

        speedDial.style.right = `${newRight}px`
        speedDial.style.bottom = `${newBottom}px`
      }

      const upListener = () => {
        document.removeEventListener("mousemove", move)
        document.removeEventListener("touchmove", move)
        document.removeEventListener("mouseup", upListener)
        document.removeEventListener("touchend", upListener)
      }

      document.addEventListener("mousemove", move)
      document.addEventListener("touchmove", move)
      document.addEventListener("mouseup", upListener)
      document.addEventListener("touchend", upListener)
    },
    async deleteAlert(id) {
      try {
        const response = await fetch(`${config.apiUri}/alerts/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        })
        this.$toast.success("L'alerte a bien été supprimée")
        socket.emit("newAlert")

        window.location.reload()
      } catch (e) {
        console.log(e)
      }
    },
    async postAlert() {
      const formData = new FormData()
      formData.append("message", this.newAlert.message)
      formData.append("state", this.newAlert.state)
      try {
        const response = await fetch(`${config.apiUri}/alerts`, {
          mode: "cors",
          method: "POST",

          body: formData,
        })

        this.dialogAlert = false

        this.$toast.success("L'alerte a bien été ajoutée")

        socket.emit("newAlert")

        window.location.reload()
      } catch (e) {
        console.log(e)
      }
    },
    async fetchAlerts() {
      this.alerts = []
      this.messages = ""

      try {
        const response = await fetch(`${config.apiUri}/alerts`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        const data = await response.json()

        data.alerts.forEach((alert, index) => {
          this.alerts.push({
            id: alert.id,
            state: alert.state,
            message: alert.message,
            datetime: alert.date_posted,
            datetime_display: moment(alert.date_posted).format("DD/MM/YYYY HH:mm"),
          })
          this.messages += `${moment(alert.date_posted).format("DD/MM/YYYY HH:mm")} ${alert.message} ${
            index !== data.alerts.length - 1 ? " | " : ""
          }`
        })
      } catch (e) {
        console.log(e)
      }
    },
    handleNotificationOpen(value) {
      this.notificationOpened = value // Met à jour la variable avec la valeur reçue de VerticalNavMenu
    },
    handleCount(value) {
      this.count = value // Met à jour la variable avec la valeur reçue de VerticalNavMenu
    },
    querySelections(v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter(e => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    select(newSelectedArray) {
      switch (newSelectedArray) {
        case "Tableau de bord":
          this.$router.push("/dashboard").catch(() => {})
          break
        case "Générateur de pdf":
          this.$router.push("/pdf/generate").catch(() => {})
          break
        case "Configuration des pdf":
          this.$router.push("/pdf/configuration").catch(() => {})
          break
        case "Historique des pdf":
          this.$router.push("/pdf/history").catch(() => {})
          break
        case "Boutique":
          this.$router.push("/store").catch(() => {})
          break
        case "Gestion des comptes":
          this.$router.push("/mainaccount/settings").catch(() => {})
          break
        case "Mon compte":
          this.$router.push("/account/setting").catch(() => {})
          break
        default:
          this.$router.push("/dashboard").catch(() => {})
          break
      }
      this.loading = false
    },
  },

  setup() {
    const isDrawerOpen = ref(null)
    let searchText = ref("")
    const list = [
      "Tableau de bord",
      "Générateur de pdf",
      "Configuration des pdf",
      "Historique des pdf",
      "Boutique",
      "Gestion des comptes",
      "Mon compte",
    ]

    function filteredList() {
      return list.filter(data => data.toLowerCase().includes(searchText.value.toLowerCase()))
    }

    return {
      isDrawerOpen,
      searchText,
      filteredList,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.drawer-two {
  margin: 0px 0px 0px 75px !important;
  // position: absolute !important;
}
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.app-content-container {
  padding: 10px 0px 0px 150px !important;
  height: 100%;
}

.main-padding {
  padding: 0px 100px 60px 0px !important;
}

@media screen and (min-width: 2000px) {
  .app-content-container {
    padding: 10px 0px 0px 150px !important;
    max-width: 1700px !important;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .app-content-container {
    padding: 10px 0px 0px 20px !important;
    margin: auto;
  }

  .main-padding {
    padding: 0px 20px 20px 0px !important;
  }

  .footer-padding {
    padding: 0px 0px 0px !important;
  }
}

.boxed-container {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}

.app-padding {
  padding: 0px 0px 0px 0px !important;
}

.drawer-padding {
  padding: 200px 0px 0px 0px !important;
}

.footer-padding {
  padding: 0px 0px 0px 70px !important;
}

.scrolling-text {
  overflow: hidden;
  width: 100%;
}

.text-container {
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

.text-content {
  font-size: 18px;
  padding-left: 100%; /* La largeur du conteneur pour forcer le défilement */
}

.scrolling-texte {
  white-space: nowrap;
  overflow: hidden;
  animation: scrolling 30s linear infinite;
}

@keyframes scrolling {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.v-alert {
  overflow: hidden;
}
</style>
