var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$vuetify.breakpoint.xs)?_c('v-navigation-drawer',{staticClass:"app-navigation-menu",attrs:{"permanent":"","color":"primary","app":""}},[_vm._l((_vm.items),function(item,index){return ((item.admin && _vm.isAdmin) || (item.collaborator && _vm.isCollaborator) || (item.guest && _vm.isGuest))?_c('div',{key:index,staticClass:"btn-group"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.badgePresent)?_c('v-badge',{attrs:{"content":"6","color":"EoleError","overlap":"","offset-x":"20","offset-y":"20"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"btn-menu white--text transparent",staticStyle:{"box-shadow":"none !important"},attrs:{"fab":"","active-class":'bg-gradient-primary EoleYellow--text',"to":{ name: item.to },"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{class:{ 'alternate-icon-small': !item.icon }},[_vm._v(" "+_vm._s(item.icon || item.alternateIcon)+" ")])],1)],1):_c('v-btn',_vm._g(_vm._b({staticClass:"btn-menu white--text transparent",staticStyle:{"box-shadow":"none !important"},attrs:{"fab":"","active-class":'bg-gradient-primary EoleYellow--text',"to":{ name: item.to },"small":""}},'v-btn',attrs,false),on),[_c('i',{class:item.class,on:{"mouseover":function($event){return _vm.hoverHandler($event, item.animation)},"mouseleave":function($event){return _vm.stopSpin($event, item.animation)}}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title)+" ")])])],1):_vm._e()}),_c('div',{staticClass:"btn-group"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":"","width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var odn = ref.odn;
var atdtrs = ref.atdtrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-menu white--text transparent",staticStyle:{"box-shadow":"none !important"},attrs:{"fab":"","active-class":'bg-gradient-primary EoleYellow--text',"small":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fa-solid fa-plus sizeIcon"})])]}}],null,true)},[_c('span',[_vm._v("Ajouter un raccourcis ")])])]}}],null,false,3876512466)},[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""}},_vm._l((_vm.shortsProposed),function(shortcut){return (
              (!shortcut.disable && shortcut.admin && _vm.isAdmin) ||
              (shortcut.collaborator && _vm.isCollaborator) ||
              (shortcut.guest && _vm.isGuest)
            )?_c('v-list-item',{key:shortcut.id,on:{"click":function($event){return _vm.shortcutChecked(shortcut)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":shortcut.use,"color":"EoleBlue"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(shortcut.title)+" ")])],1),_c('v-list-item-icon',[_c('i',{class:['fa-solid fa-star', shortcut.use ? 'EoleYellow--text' : '']})])]},proxy:true}],null,true)}):_vm._e()}),1)],1)],1)],1),_c('v-spacer'),_c('div',{staticClass:"app-bar-user-menu"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.isAdmin)?_c('v-btn',_vm._g(_vm._b({staticClass:"btn-menu white--text transparent ml-1 pa-5",staticStyle:{"box-shadow":"none !important"},attrs:{"id":"btn-manage","fab":"","active-class":'bg-gradient-primary EoleYellow--text',"small":"","to":{ name: 'settingsOffice' }}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fa-solid fa-gear fa-2x",on:{"mouseover":function($event){return _vm.hoverHandler($event, 'spin')},"mouseleave":function($event){return _vm.stopSpin($event, 'spin')}}})]):_vm._e()]}}],null,false,441326891)},[_c('span',[_vm._v("Mon cabinet "+_vm._s(_vm.agency)+" ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.isAdmin || _vm.isCollaborator)?_c('v-badge',{attrs:{"value":_vm.count,"content":_vm.count,"color":"EoleError","overlap":"","offset-x":"17","offset-y":"15"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"white--text transparent mb-5 pa-5",staticStyle:{"box-shadow":"none !important"},attrs:{"fab":"","x-large":"","icon":"","outlined":""},on:{"click":_vm.emitOpen,"mouseover":function($event){return _vm.hoverHandler($event, 'shake')},"mouseleave":function($event){return _vm.stopSpin($event, 'shake')}}},'v-btn',attrs,false),on),[(_vm.count > 0)?_c('i',{staticClass:"fa-solid fa-bell sizeIcon"}):_c('i',{staticClass:"fa-regular fa-bell sizeIcon"})])],1):_vm._e()]}}],null,false,1610431772)},[_c('span',[_vm._v("Mes notifications ("+_vm._s(_vm.count)+") ")])]),_c('app-bar-user-menu')],1)],2):_c('CurvedBottomNavigation',{attrs:{"options":_vm.options,"foreground-color":"#FCC03C","badge-color":"#C1272D","background-color":"#001F47","icon-color":"white"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }