require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#001F47',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#28a745',
        info: '#001F47',
        warning: '#FCC03C',
        error: '#FF4C51',
        EoleBlue: "#001F47",
        EoleYellow: "#FCC03C",
        EoleGray: "#9D9D9C",
        GrayText: "#8F8F8F",
        EoleError: "#C1272D",
        EoleGreen: "52b788",
        EoleBlueLighten: "#e1e5e9",
        EoleErrorBase: "#ff4c51",
      },
      dark: {
        primary: '#FCC03C',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#28a745',
        info: '#001F47',
        warning: '#FCC03C',
        error: '#FF4C51',
        white: '#FFFFFF',
        EoleBlue: "#001F47",
        EoleYellow: "#FCC03C",
        EoleGray: "#9D9D9C",
        GrayText: "#8F8F8F",
        EoleError: "#C1272D",
        EoleGreen: "52b788",
      },
    },
  },
}
