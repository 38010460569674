import io from 'socket.io-client';

const socket = io("https://levana.anavel.bzh:4001")
socket.on("connect", () => {
  socket.emit("userConnected", localStorage.getItem("complete_name"), localStorage.getItem(
    "user_id"))
})

function sendNotification(idUser, type, title, action) {
  socket.emit("notification", {
    roomName: idUser,
    type: type,
    info: {
      title: title,
      action: action,
      datetime: new Date(),
    },
  })
}

export {
  socket,
  sendNotification
}
