<template>
  <v-navigation-drawer hide-overlay v-model="isOpen" absolute temporary width="500" app class="drawer-two">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Mes notifications ({{ itemsNotifications.length }})</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list three-line dense>
      <template v-for="(item, index) in itemsNotifications">
        <v-divider v-if="index > 0" :key="index"></v-divider>

        <v-list-item three-line link>
          <v-list-item-avatar>
            <v-avatar color="primary white--text" size="48">
              <i :class="item.class"></i>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >{{ item.title }}
              <v-chip class="ml-2" v-if="index === 0" small color="EoleYellow">Nouveau !</v-chip></v-list-item-title
            >
            <v-list-item-subtitle class="item-title" v-if="!!item.action">{{ item.action }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text v-text="getFormattedDate(item.datetime)"></v-list-item-action-text>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on" @click="remove(item)">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Supprimer cette notification</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import "@mdi/font/css/materialdesignicons.css" // Load icons
import moment from "moment"
moment.locale("fr")

import { sendNotification, socket } from "@/utils/socket.js"

export default {
  data: () => ({
    itemsNotifications: [],
    drawer: null,
  }),
  mounted() {
    if (localStorage.getItem("notifications")) {
      this.itemsNotifications = JSON.parse(localStorage.getItem("notifications"))
      this.inverseArray(this.itemsNotifications)

      this.$emit("count", this.itemsNotifications.length)
    }

    socket.on("notificationAll", data => {
      let notification = data

      switch (notification.type) {
        case 1:
          notification.info.class = "fa-solid fa-phone-volume mr-1"
          break
        case 2:
          notification.info.class = "fa-solid fa-user-plus mr-1"
          break
        case 3:
          notification.info.class = "fa-solid fa-share-from-square mr-1"
          break
      }

      this.itemsNotifications.push(notification.info)
      localStorage.setItem("notifications", JSON.stringify(this.itemsNotifications))
      this.inverseArray(this.itemsNotifications)

      this.$emit("count", this.itemsNotifications.length)
      // this.$toast.warning(notification.info.title, {
      //   position: "bottom-right",
      //   icon: notification.class,
      //   timeout: false,
      // })
    })

    socket.on("notification", data => {
      let notification = data

      switch (notification.type) {
        case 1:
          notification.info.class = "fa-solid fa-phone-volume mr-1"
          break
        case 2:
          notification.info.class = "fa-solid fa-user-plus mr-1"
          break
      }

      this.itemsNotifications.push(notification.info)
      localStorage.setItem("notifications", JSON.stringify(this.itemsNotifications))
      this.inverseArray(this.itemsNotifications)
      this.$emit("count", this.itemsNotifications.length)
    })
  },
  components: {},
  props: ["isOpen"],
  watch: {
    isOpen(value) {
      this.notificationOpen = value // Mettre à jour la variable de données locale lorsqu'une nouvelle valeur de prop est reçue
    },
  },
  methods: {
    inverseArray(array) {
      array.reverse()
    },
    getFormattedDate(date) {
      return moment(date).calendar()
    },

    countNotifications() {
      return this.itemsNotifications.length
    },
    remove(item) {
      this.itemsNotifications.splice(this.itemsNotifications.indexOf(item), 1)
      localStorage.setItem("notifications", JSON.stringify(this.itemsNotifications))
      this.$emit("count", this.countNotifications())
    },
  },
  created() {
    // this.randomizeNotifications()

    this.$emit("count", this.itemsNotifications.length)
  },
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
// * {
//   overflow: hidden;
// }
.drawer-two {
  height: 100vh;
  position: fixed;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.item-title {
  -webkit-line-clamp: 4 !important;
}
</style>
